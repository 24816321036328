// Libs
import React, {useContext} from 'react';
// Deps.
import CheckoutBreadcrumb from './checkout-breadcrumb';
import CheckoutFormContact from './checkout-form-contact';
import OrderSummaryLoft from './order-summary-loft';
import CartFMKSummary from '../cart/cart-fmk-summary';
import CartFmkTotal from '../cart/cart-fmk-total';
import OrderTotal from './order-total';
import CheckoutFormBilling from './checkout-form-billing';
import CheckoutFormPayment from './checkout-form-payment';
import CheckLoftAvailability from '../common/commerce/check-loft-availability';
// Store.
import { useStateValue } from '../../store/state';
import { CheckoutState } from './context/checkout-context';
import LoftItemsMap from './context/loft-items-map-context';
// Assets.
import styles from './checkout.module.scss';
import locale from '../../locale/locale';
import {AppContext} from "../layout/AppLayout";

const Checkout = ({ cartType }) => {
  // console.log('::::', cartType);

  const [state] = useStateValue();
  const [activeStep] = CheckoutState();
  const { cartItems: loftCartItems } = LoftItemsMap();
  const fmkCartItems = state.cart.fmk.lineItems || [];
  const isApp = useContext(AppContext);
  return (
    <div className={styles.checkout}>
      <div className={styles.checkoutContainer}>
        <h1 className={styles.checkoutTitle}>{locale.checkoutPage.titles.pageTitle}</h1>
        <CheckoutBreadcrumb activeState={activeStep} />
        {activeStep === 1 && (
          <div className={styles.checkoutFormContainer}>
            <CheckoutFormContact />
            <div className={isApp ? styles.hidden : styles.checkoutFormContainerSidebarRight}>
              {cartType === 'loft' && (
                <>
                  <CheckLoftAvailability>
                    <OrderSummaryLoft variation="review" cartItems={loftCartItems} />
                  </CheckLoftAvailability>
                  <OrderTotal items={loftCartItems} showPoints={false} />
                </>
              )}
              {cartType === 'fmk' && (
                <>
                  <CartFMKSummary variation="review" />
                  <CartFmkTotal items={fmkCartItems} checkout={false} />
                </>
              )}
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div className={styles.checkoutFormContainer}>
            <CheckoutFormBilling cartType={cartType} customer={state.customer} />
            <div className={isApp ? styles.hidden : styles.checkoutFormContainerSidebarRight}>
              {cartType === 'loft' && (
                <>
                  <CheckLoftAvailability>
                    <OrderSummaryLoft variation="review" cartItems={loftCartItems} />
                  </CheckLoftAvailability>
                  <OrderTotal items={loftCartItems} showPoints={false} />
                </>
              )}
              {cartType === 'fmk' && (
                <>
                  <CartFMKSummary variation="review" />
                  <CartFmkTotal items={fmkCartItems} checkout={false} />
                </>
              )}
            </div>
          </div>
        )}
        {activeStep === 3 && (
          <div className={styles.checkoutFormContainer}>
            <CheckoutFormPayment cartType={cartType} customer={state.customer} />
            <div className={isApp ? styles.hidden : styles.checkoutFormContainerSidebarRight}>
              {cartType === 'loft' && (
                <>
                  <CheckLoftAvailability validate={false}>
                    <OrderSummaryLoft variation="review" cartItems={loftCartItems} />
                  </CheckLoftAvailability>
                  <OrderTotal items={loftCartItems} showPoints={false} />
                </>
              )}
              {cartType === 'fmk' && (
                <>
                  <CartFMKSummary variation="view" />
                  <CartFmkTotal items={fmkCartItems} checkout={false} />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
