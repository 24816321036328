// Libs.
import React, { useContext, useState } from 'react';
import useClassAvailability from '../../../hooks/useClassAvailability';
import { some, every } from 'lodash'
// Custom Libs.
import { checkDateChangeCartItems } from '../../../libs/class/class-validator';
import { addNotification } from '../../../libs/notify';
// State.
import LoftItemsMap from '../../checkout/context/loft-items-map-context';
// Assets.
import pageAliases from "../../../data/pageAliases.json";
import locale from '../../../locale/locale.json';
import navigate from '../../../libs/navigate';
import appPageAliases from '../../../data/appAliases';
import { AppContext } from '../../layout/AppLayout';

const CheckLoftAvailability = ({ children, validate = false }) => {
  const { cartItems, classSchedules } = LoftItemsMap();
  const [, setAvailabilityHash] = useState();
  const isApp = useContext(AppContext);
  useClassAvailability(classSchedules, setAvailabilityHash, false, 0.15);
  const availabilityLoaded = every(classSchedules, 'availability.apiLoaded');

  if (validate && availabilityLoaded) {
    const dateChanges = checkDateChangeCartItems(classSchedules, cartItems);
    const classSchedulesAvailable = every(classSchedules, 'availability.seats');
    const someClassesAreCancelled = some(classSchedules, 'availability.isCancel');
    const someClassesAreClosed = some(classSchedules, 'availability.isClosed');
    if (
      dateChanges ||
      !classSchedulesAvailable ||
      someClassesAreCancelled ||
      someClassesAreClosed
    ) {
      navigate(isApp ? appPageAliases.appCart : pageAliases.cart);
      addNotification(locale.checkoutPage.errors.changesOnPaymentStep);
    }
  }

  return <>{React.cloneElement(children, { classSchedules })}</>;
};

export default CheckLoftAvailability;
