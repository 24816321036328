// Libs.
import React, { useContext, useState } from 'react';
import * as PropTypes from 'prop-types';
import { compact, every, some, has } from 'lodash';
// State.
import { useStateValue } from '../../store/state';
import { CheckoutState } from './context/checkout-context';
import { CartCheckout, fmkCartCheckout } from '../../store/cart/actions';
import { UpdateCheckoutCustomer } from '../../store/checkout/action-creators';
import LoftItemsMap from './context/loft-items-map-context';
// Deps.
import FormInput from '../common/form-input';
import formValidators from '../../libs/form/form-validator';
import { addNotification, clearNotifications } from '../../libs/notify';
// Assets.
import locale from '../../locale/locale.json';
import styles from './checkout-form.module.scss';
import pageAliases from '../../data/pageAliases.json';
import appPageAliases from '../../data/appAliases';
// Loaders.
import FormLoader from '../common/loaders/form-loader';
import ButtonLoader from '../common/loaders/button-loader';
import { checkDateChangeCartItems } from '../../libs/class/class-validator';
import { AppContext } from '../layout/AppLayout';
import AppShown from '../app/common/app-shown';
import navigate from '../../libs/navigate';
import getWindow from '../../libs/getWindow';
// Hooks.
import { useCheckIfValidToken, tokenExpiredLogout } from '../../hooks/useAuthenticationExpire';

const CheckoutFormBilling = ({ cartType, customer }) => {
  const [state, dispatch] = useStateValue();
  // Step tracker
  const [activeStep, adjustStep] = CheckoutState();
  // Customer state
  const activeUser = state.customer;
  const checkoutCustomer = state.checkout.customer;
  const [submission, setSubmission] = useState(false);
  // Loft mapped cart items class schedules from context.
  const { classSchedules, cartItems } = LoftItemsMap();
  const isApp = useContext(AppContext);
  const handleTokenCheck = useCheckIfValidToken(() => {
    tokenExpiredLogout(isApp, dispatch);
  });
  // Form values
  const [mainError, setMainError] = useState();
  // Display errors through snackbar for mobile.
  if (isApp && mainError) {
    const mainErrorMobileApp = mainError.replace(/^\* /, '');
    addNotification(mainErrorMobileApp, 'error');
    clearNotifications();
    setMainError(false);
  }
  const getDocument = getWindow('document');

  const onFocus = () => {
    if (isApp && getDocument) {
      const selector = getDocument.querySelector('[class*="useNavigationDrawer-module--drawer--container"]');
      if (has(selector, 'scrollTop')) {
        selector.scrollTop = 2000;
      }
    }
  };
  const [name, setName] = useState({
    value: `${checkoutCustomer.FirstName} ${checkoutCustomer.LastName}`,
    error: false,
  });
  const [EmailAddress, setEmailAddress] = useState({
    value: checkoutCustomer.EmailAddress || activeUser.EmailAddress,
    error: false,
  });
  const [PrimaryPhone, setPrimaryPhone] = useState({
    value: checkoutCustomer.PrimaryPhone || activeUser.PrimaryPhone,
    error: false,
  });
  const [PrimaryAddress_Street1, setPrimaryAddress_Street1] = useState({
    value: checkoutCustomer.PrimaryAddress_Street1 || activeUser.PrimaryAddress_Street1,
    error: false,
  });
  const [PrimaryAddress_Street2, setPrimaryAddress_Street2] = useState({
    value: checkoutCustomer.PrimaryAddress_Street2 || activeUser.PrimaryAddress_Street2,
    error: false,
  });
  const [PrimaryAddress_City, setPrimaryAddress_City] = useState({
    value: checkoutCustomer.PrimaryAddress_City || activeUser.PrimaryAddress_City,
    error: false,
  });
  const [PrimaryAddress_PostalCode, setPrimaryAddress_PostalCode] = useState({
    value: checkoutCustomer.PrimaryAddress_PostalCode,
    error: false,
  });
  const [PrimaryAddress_Province, setPrimaryAddress_Province] = useState({
    value: checkoutCustomer.PrimaryAddress_Province || activeUser.PrimaryAddress_Province || 'ON',
    error: false,
  });
  const [PrimaryAddress_Country, setPrimaryAddress_Country] = useState({
    value: checkoutCustomer.PrimaryAddress_Country || activeUser.PrimaryAddress_Country || 'CA',
    error: false,
  });

  const checkRequired = (get, set) => {
    const error = get.error || !get.value;
    set({ ...get, error: error });
    return error;
  };

  const formHasErrors = () => {
    let errors = [];
    // Enforce error checking
    errors.push(checkRequired(PrimaryAddress_Street1, setPrimaryAddress_Street1));
    errors.push(checkRequired(PrimaryAddress_City, setPrimaryAddress_City));
    errors.push(checkRequired(PrimaryAddress_PostalCode, setPrimaryAddress_PostalCode));
    errors.push(checkRequired(PrimaryAddress_Province, setPrimaryAddress_Province));
    errors.push(checkRequired(PrimaryAddress_Country, setPrimaryAddress_Country));
    errors = !!compact(errors).length;
    setMainError(errors);
    return errors;
  };
  let phoneFormated = PrimaryPhone.value.match(/^(\d{3})(\d{3})(\d{4})$/);
  phoneFormated = `${phoneFormated[1]}-${phoneFormated[2]}-${phoneFormated[3]}`;
  const CartCheckoutLoft = async () => {
    try {
      const result = await CartCheckout(
        state.cart[cartType].lineItems,
        state.checkout.customer,
        state.user,
        dispatch,
        isApp,
      );

      if (result) {
        adjustStep(activeStep + 1);
        return true;
      }
      setMainError(locale.checkoutPage.errors.errorProcessingRequest);
      return false;
    } catch (e) {
      // On failure, let the user know something when wrong, to try again.
      setMainError(locale.checkoutPage.errors.errorProcessingRequest);
      return false;
    }
  };

  const CartCheckoutFMK = async () => {
    try {
      const result = await fmkCartCheckout(
        state.cart[cartType].lineItems,
        state.cart[cartType].pickup,
        state.checkout.customer,
        state.user,
        dispatch,
      );

      // console.log('checkoutUrl', state, result);

      if (result) {
        adjustStep(activeStep + 1);
        return true;
      }
      setMainError(locale.checkoutPage.errors.errorProcessingRequest);
      return false;
    } catch (e) {
      // On failure, let the user know something when wrong, to try again.
      setMainError(locale.checkoutPage.errors.errorProcessingRequest);
      return false;
    }
  };

  const submitForm = async (e) => {
    setSubmission(true);
    e.preventDefault();

    if (formHasErrors()) {
      setSubmission(false);
      setMainError(locale.checkoutPage.errors.invalidFormFields);
      return false;
    }

    // Update the cart customer info.
    dispatch(
      UpdateCheckoutCustomer({
        PrimaryAddress_Street1: PrimaryAddress_Street1.value,
        PrimaryAddress_Street2: PrimaryAddress_Street2.value,
        PrimaryAddress_City: PrimaryAddress_City.value,
        PrimaryAddress_PostalCode: PrimaryAddress_PostalCode.value,
        PrimaryAddress_Province: PrimaryAddress_Province.value,
        PrimaryAddress_Country: PrimaryAddress_Country.value,
      }),
    );

    // Extract cart items loft IDs.
    if (cartType === 'loft') {
      const dateChanges = checkDateChangeCartItems(classSchedules, cartItems);
      const availabilityLoaded = every(classSchedules, 'availability.apiLoaded');
      const classSchedulesAvailable = every(classSchedules, 'availability.seats');
      const someClassesAreCancelled = some(classSchedules, 'availability.isCancel');
      const someClassesAreClosed = some(classSchedules, 'availability.isClosed');

      // Something with availability has changed, prevent users from moving forward.
      if (dateChanges || !classSchedulesAvailable) {
        if (dateChanges) {
          addNotification(locale.checkoutPage.errors.dateTimeChanges, 'error');
        } else if (someClassesAreCancelled) {
          addNotification(locale.checkoutPage.errors.cancelled, 'error');
        } else if (someClassesAreClosed) {
          addNotification(locale.checkoutPage.errors.closed, 'error');
        } else {
          addNotification(locale.checkoutPage.errors.soldOut, 'error');
        }

        navigate(isApp ? appPageAliases.appCart : pageAliases.cart);
        return;
      }

      if (!availabilityLoaded) {
        isApp ?
          setMainError(locale.checkoutPage.errors.availabilityLoadFailure) :
          addNotification(locale.checkoutPage.errors.availabilityLoadFailure);
      } else {
        await handleTokenCheck();
        // Slight timeout to ensure the checkout customer state is updated before generating payment step.
        setTimeout(() => {
          CartCheckoutLoft();
        }, 100);
      }

      setSubmission(false);
    } else {
      const result = await CartCheckoutFMK();
      setSubmission(result);
    }
  };

  return (
    <form className={styles.checkoutForm} data-type="billing" onSubmit={(e) => submitForm(e)}>
      {submission && <FormLoader/>}
      <div className={styles.checkoutFormErrorMessage}>{!isApp && mainError}</div>
      <div className={styles.checkoutFormContainer}>
        {!isApp &&
        <>
          <FormInput
            className={styles.checkoutFormName}
            type="text"
            label={locale.form.name}
            placeholder={locale.form.name}
            disabled
            maxLength={50}
            onChange={(e) => formValidators.exists(e.target.value, name, setName)}
            value={name.value}
            error={name.error}
            errorMsg={name.error ? locale.form.nameError : ''}
          />
          <div className={styles.checkoutFormRow}>
            <FormInput
              className={styles.checkoutFormEmail}
              label={locale.form.email}
              type="email"
              placeholder={locale.form.emailPlaceholder}
              maxLength={100}
              disabled
              onChange={(e) => formValidators.email(e.target.value, EmailAddress, setEmailAddress)}
              value={EmailAddress.value}
              error={EmailAddress.error}
              errorMsg={EmailAddress.error ? locale.form.emailError : ''}
            />
            <FormInput
              className={styles.checkoutFormPhone}
              label={locale.form.phone}
              type="tel"
              placeholder={locale.form.phonePlaceholder}
              disabled
              onChange={(e) => formValidators.phone(e.target.value, PrimaryPhone, setPrimaryPhone)}
              value={PrimaryPhone.value}
              error={PrimaryPhone.error}
              errorMsg={PrimaryPhone.error ? locale.form.phoneError : ''}
            />
          </div>
        </>
        }
        <AppShown>
          <div className={styles.checkoutFormRowApp}>
            <div>{name.value}</div>
            <div>{EmailAddress.value}</div>
            <div>{phoneFormated}</div>
          </div>
        </AppShown>
        <hr/>
        <FormInput
          className={styles.checkoutFormAddress}
          label={locale.form.billingAddress}
          type="text"
          placeholder={''}
          onChange={(e) =>
            formValidators.exists(e.target.value, PrimaryAddress_Street1, setPrimaryAddress_Street1)
          }
          value={PrimaryAddress_Street1.value}
          error={PrimaryAddress_Street1.error}
          errorMsg={PrimaryAddress_Street1.error ? locale.form.addressError : ''}
        />
        <FormInput
          className={styles.checkoutFormAddress2}
          label={locale.form.billingAddress2}
          type="text"
          placeholder={''}
          onChange={(e) => {
            const formLocalState = { PrimaryAddress_Street2, value: e.target.value, error: false };
            setPrimaryAddress_Street2(formLocalState);
          }}
          value={PrimaryAddress_Street2.value}
          error={PrimaryAddress_Street2.error}
          errorMsg={PrimaryAddress_Street2.error ? locale.form.addressError : ''}
        />
        <div className={styles.checkoutFormRow}>
          <FormInput
            className={styles.checkoutFormCity}
            label={locale.form.city}
            type="text"
            placeholder={''}
            onChange={(e) =>
              formValidators.exists(e.target.value, PrimaryAddress_City, setPrimaryAddress_City)
            }
            value={PrimaryAddress_City.value}
            error={PrimaryAddress_City.error}
            errorMsg={PrimaryAddress_City.error ? locale.form.cityError : ''}
          />
          <FormInput
            className={styles.checkoutFormProvince}
            label={locale.form.province}
            type="select"
            placeholder={''}
            options={[
              { value: 'AB', label: 'Alberta' },
              { value: 'BC', label: 'British Columbia' },
              { value: 'MB', label: 'Manitoba' },
              { value: 'NB', label: 'New Brunswick' },
              { value: 'NL', label: 'Newfoundland and Labrador' },
              { value: 'NT', label: 'Northwest Territories' },
              { value: 'NS', label: 'Nova Scotia' },
              { value: 'NU', label: 'Nunavut' },
              { value: 'ON', label: 'Ontario' },
              { value: 'PE', label: 'Prince Edward Island' },
              { value: 'QC', label: 'Quebec' },
              { value: 'SK', label: 'Saskatchewan' },
              { value: 'YT', label: 'Yukon' },
            ]}
            onChange={(e) =>
              formValidators.exists(
                e.target.value,
                PrimaryAddress_Province,
                setPrimaryAddress_Province,
              )
            }
            value={PrimaryAddress_Province.value}
            error={PrimaryAddress_Province.error}
            errorMsg={PrimaryAddress_Province.error ? locale.form.provinceError : ''}
          />
        </div>
        <div className={styles.checkoutFormRow}>
          <FormInput
            className={styles.checkoutFormPostalCode}
            label={locale.form.postalCode}
            type="text"
            placeholder={''}
            onChange={(e) =>
              formValidators.postalCode(
                e.target.value,
                PrimaryAddress_PostalCode,
                setPrimaryAddress_PostalCode,
              )
            }
            onFocus={onFocus}
            value={PrimaryAddress_PostalCode.value}
            error={PrimaryAddress_PostalCode.error}
            errorMsg={PrimaryAddress_PostalCode.error ? locale.form.postalCodeError : ''}
          />
          <FormInput
            className={styles.checkoutFormCountry}
            label={locale.form.country}
            type="select"
            placeholder={''}
            options={[
              {
                value: 'Canada',
                label: 'Canada',
              },
            ]}
            required={true}
            onChange={(e) =>
              formValidators.exists(
                e.target.value,
                PrimaryAddress_Country,
                setPrimaryAddress_Country,
              )
            }
            value={PrimaryAddress_Country.value}
            error={PrimaryAddress_Country.error}
            errorMsg={PrimaryAddress_Country.error ? locale.form.countryError : ''}
          />
        </div>
        <div className={styles.checkoutFormButtons}>
          <button
            className={styles.checkoutFormButtonInverse}
            onClick={() => adjustStep(activeStep - 1)}
          >
            {locale.checkoutPage.buttons.backToContact}
            {submission && <ButtonLoader/>}
          </button>
          <button className={styles.checkoutFormButton}>
            {locale.checkoutPage.buttons.continueToPayment}
            {submission && <ButtonLoader/>}
          </button>
        </div>
      </div>
    </form>
  );
};

CheckoutFormBilling.propTypes = {
  customer: PropTypes.object,
};

export default CheckoutFormBilling;
