// @flow
import { baseDrupalClientService } from './baseDrupalClientService'

export class drupalClientService extends baseDrupalClientService {
  // Extended just to run the singleton instantiation separate from base clase,
  // this allow import without singleton instantiation at child classes.
}

// Initialize singleton instance.
const singleton = new drupalClientService();
Object.freeze(singleton);

export default singleton;