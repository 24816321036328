// Libs.
import { isEmpty, keyBy } from 'lodash';
// State.
import { useStateValue } from '../../store/state';
import { RemoveItem } from '../../store/cart/action-creators';
// Custom Libs.
import { addItemsPrice, addLineItemsTotal, calcLineItemsTotal } from './class-items-calculator';
// Hooks.
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';

export const useCartClassSantizer = () => {
  const [state, dispatch] = useStateValue();
  const cartItems = state.cart.loft.lineItems;

  const [{ all: classes }] = useClassesStaticQuery();
  const classSchedulesById = keyBy(classes, 'nid');
  // Check if the cart has any invalid classes that are not currently present in the class list
  // and pass the invalid item to the cart summary so that we can later display an error on the UI
  // before that invalid item is deleted.
  const classScheduleArray = Object.keys(classSchedulesById).map((key) => classSchedulesById[key]);
  const validCartItems = cartItems.filter((item) => {
    return classScheduleArray.filter((loftClass) => loftClass.loftId === item.loftId).length > 0;
  });
  const invalidCartItems = cartItems.filter((item) => {
    return classScheduleArray.filter((loftClass) => loftClass.loftId === item.loftId).length === 0;
  });
  cartItems.forEach((item) => {
    if (!classSchedulesById[item.id]) {
      dispatch(RemoveItem(item));
    }
  });

  return {
    validCartItems: validCartItems,
    invalidCartItems: invalidCartItems,
  };
};
