// Libs.
import React, { useContext, useEffect } from 'react';
import { keyBy } from 'lodash';
// Store.
import { useStateValue } from '../../../store/state';
// Hooks.
import { useClassesStaticQuery } from '../../../hooks/useClassesStaticQuery';

// Context used to share items map to classes schedules data across components.
export const LoftItemsMapContext = React.createContext({});

export const LoftItemsMapInit = () => {
  const [state] = useStateValue();
  const cartItems = state.cart.loft.lineItems || [];

  const [{ all: classes }] = useClassesStaticQuery();
  const classSchedulesById = keyBy(classes, 'nid');
  const cartItemClassSchedules = cartItems.map((item) => {
    return classSchedulesById[item.id];
  });

  return {
    cartItems: cartItems,
    classSchedules: cartItemClassSchedules,
  };
};

const LoftItemsMap = () => {
  const [state] = useStateValue();
  const cartItems = state.cart.loft.lineItems || [];
  const [loftItemsMap, setLoftItemsMap] = useContext(LoftItemsMapContext);
  loftItemsMap.cartItems = cartItems;

  useEffect(() => {
    setLoftItemsMap(loftItemsMap);
  }, [cartItems]);

  return loftItemsMap;
}

export default LoftItemsMap;