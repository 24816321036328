// Libs
import React, {useContext, useEffect, useState} from 'react';
import { compact, isEmpty } from 'lodash';
// State.
import { useStateValue } from '../../store/state';
import { CheckoutState } from './context/checkout-context';
import formValidators from '../../libs/form/form-validator';
// import useEnvironment from '../../hooks/useEnvironment';
import useCustomer from '../../hooks/useCustomer';
import { UpdateCheckoutCustomer } from '../../store/checkout/action-creators';
// Deps.
import FormInput from '../common/form-input';
import TyrCardInput from '../common/tyrCardInput';
// Assets.
import locale from '../../locale/locale.json';
import styles from './checkout-form.module.scss';
import FormLoader from '../common/loaders/form-loader';
import ButtonLoader from '../common/loaders/button-loader';
import {AppContext} from "../layout/AppLayout";

const CheckoutFormContact = () => {
  const [state, dispatch] = useStateValue();
  // Step tracker
  const [activeStep, adjustStep] = CheckoutState();
  // Customer state
  const { getCustomer } = useCustomer();
  const activeUser = state.customer;
  const checkoutCustomer = state.checkout.customer;
  const [submission, setSubmission] = useState(false);
  // const env = useEnvironment();
  // Form values
  const [mainError, setMainError] = useState(false);
  // const [termsError, setTermsError] = useState(checkoutCustomer.acceptsTerms || false);
  const [FirstName, setFirstName] = useState({
    value: checkoutCustomer.FirstName || activeUser.FirstName,
    error: false,
  });
  const [isFocus, setFocus] = useState(false);
  const [LastName, setLastName] = useState({
    value: checkoutCustomer.LastName || activeUser.LastName,
    error: false,
  });
  const [EmailAddress, setEmailAddress] = useState({
    value: checkoutCustomer.EmailAddress || activeUser.EmailAddress,
    error: false,
  });
  const [PrimaryPhone, setPrimaryPhone] = useState({
    value: checkoutCustomer.PrimaryPhone || activeUser.PrimaryPhone,
    error: false,
  });
  // Tyr card elements/state
  const [tyrNumber, setTyrNumber] = useState(
    checkoutCustomer.CardNumber || activeUser.CardNumber || '',
  );
  const [tyrError, setTyrError] = useState(false);
  const [tyrValid, setTyrValid] = useState(false);

  const checkRequired = (get, set) => {
    const error = get.error || !get.value;
    set({ ...get, error: error });
    return error;
  };
  const isApp = useContext(AppContext);

  const formHasErrors = () => {
    let errors = [];
    // Enforce error checking
    errors.push(checkRequired(FirstName, setFirstName));
    errors.push(checkRequired(LastName, setLastName));
    errors.push(checkRequired(EmailAddress, setEmailAddress));
    errors.push(checkRequired(PrimaryPhone, setPrimaryPhone));
    errors = !!compact(errors).length;
    // console.log('error check', errors);
    setMainError(errors);
    return errors;
  };

  // Load latest customer data.
  useEffect(() => {
    getCustomer();
  }, [state.user.access_token]);

  // Update customer from state if customer details change
  useEffect(() => {
    if (activeUser.CRMCustomerID) {
      setFirstName({
        ...FirstName,
        value: checkoutCustomer.FirstName || activeUser.FirstName,
      });
      setLastName({
        ...LastName,
        value: checkoutCustomer.LastName || activeUser.LastName,
      });
      setEmailAddress({
        ...EmailAddress,
        value: checkoutCustomer.EmailAddress || activeUser.EmailAddress,
      });
      setPrimaryPhone({
        ...PrimaryPhone,
        value: checkoutCustomer.PrimaryPhone || activeUser.PrimaryPhone,
      });
      setTyrNumber(checkoutCustomer.CardNumber || activeUser.CardNumber);
    }
  }, [activeUser]);

  // Remove terms & conditions error when terms opt-in is checked.
  /*useEffect(() => {
    if (state.checkout.optIns.terms) {
      setTermsError(false);
    }
  }, [state.checkout.optIns.terms]);*/

  const submitForm = (e) => {
    setSubmission(true);
    e.preventDefault();

    // Check for/raise errors to the user.
    if (formHasErrors()) {
      setSubmission(false);
      return false;
    }
    // else if (!state.checkout.optIns.terms) {
    //   setTermsError(true);
    //   setMainError(true);
    //   return false;
    // }

    // If the TYR card is blank, or valid, allow the user to continue
    if (!tyrNumber || tyrValid) {
      // Update the cart customer info.
      dispatch(
        UpdateCheckoutCustomer({
          FirstName: FirstName.value,
          LastName: LastName.value,
          EmailAddress: EmailAddress.value,
          PrimaryPhone: PrimaryPhone.value,
          TYRCardNumber: tyrNumber,
          CRMCustomerID: activeUser.CRMCustomerID || '',
          acceptsTerms: true,
        }),
      );

      // console.log('looks good?', mainError);

      // TODO: update local state, before proceeding.
      adjustStep(activeStep + 1);
      setSubmission(false);

      // TODO: Below is deprecated as per LON-127 at pre-launch. Leaving code in place for future needs.
      // No member signup so go straight to next step.
      /*if (!state.checkout.optIns.member || env !== 'development') {
        continueStep();
      } else {
        const responsePromise = registerMember();
        // On success response continue with next step, otherwise show the main
        // error.
        responsePromise.then(data => {
          if (!data) {
            setMainError(true);
          } else {
            continueStep();
          }
        });
      }*/
    }
  };

  // TODO: Below is deprecated as per LON-127 at pre-launch. Leaving code in place for future needs.
  // Handle member sign up.
  /*const registerMember = async () => {
    const customer = {
      EmailAddress: email.value,
      FirstName: firstName.value,
      LastName: lastName.value,
      PrimaryPhone: phone.value,
      PrimaryAddress_PostalCode: 'M3C3M8',
      Password: '',
    };

    return crmService.customerCreate(customer);
  };*/

  return (
    <form className={styles.checkoutForm} data-type="contact" onSubmit={(e) => submitForm(e)} data-focus={isFocus}>
      {submission && <FormLoader/>}
      <div className={styles.checkoutFormErrorMessage}>
        {mainError ? locale.checkoutPage.errors.invalidFormFields : ''}
      </div>
      <div className={styles.checkoutFormContainer}>
        <div className={styles.checkoutFormNames}>
          <FormInput
            className={styles.checkoutFormFirstName}
            type="text"
            label={locale.form.firstName}
            placeholder={locale.form.firstName}
            maxLength={50}
            onChange={(e) => formValidators.exists(e.target.value, FirstName, setFirstName)}
            value={FirstName.value}
            error={FirstName.error && mainError}
            errorMsg={FirstName.error && locale.form.firstNameError}
          />
          <FormInput
            className={styles.checkoutFormLastName}
            type="text"
            label={locale.form.lastName}
            placeholder={locale.form.lastName}
            maxLength={50}
            onChange={(e) => formValidators.exists(e.target.value, LastName, setLastName)}
            value={LastName.value}
            error={LastName.error && mainError}
            errorMsg={LastName.error && locale.form.lastNameError}
          />
        </div>
        <FormInput
          className={styles.checkoutFormEmail}
          label={locale.form.email}
          type="email"
          placeholder={locale.form.emailPlaceholder}
          maxLength={100}
          onChange={(e) => formValidators.email(e.target.value, EmailAddress, setEmailAddress)}
          value={EmailAddress.value}
          error={EmailAddress.error && mainError}
          errorMsg={EmailAddress.error && locale.form.emailError}
          disabled={!state.checkout.isGuest && 'disabled'}
        />
        <FormInput
          className={styles.checkoutFormPhone}
          label={locale.form.phone}
          type="tel"
          onBlur={() => isApp && setFocus(false)}
          onFocus={() => isApp && setFocus(true)}
          placeholder={locale.form.phonePlaceholder}
          onChange={(e) => formValidators.phone(e.target.value, PrimaryPhone, setPrimaryPhone)}
          value={PrimaryPhone.value}
          error={PrimaryPhone.error && mainError}
          errorMsg={PrimaryPhone.error && locale.form.phoneError}
        />
        {/* Only display the TYR field if the user is logged in. Can not be used by guests. */}
        {!state.checkout.isGuest && !isEmpty(activeUser.CardNumber) && (
          <TyrCardInput
            className={styles.checkoutFormTyrCard}
            label={locale.form.tyrNumber.label}
            // If the user has an existing TYR card, don't allow them to change it.
            disabled={!!activeUser.CardNumber}
            state={{
              value: { get: tyrNumber, set: setTyrNumber },
              error: { get: tyrError, set: setTyrError },
              valid: { get: tyrValid, set: setTyrValid },
            }}
            showCta={false}
          />
        )}
        {/*{termsError && <div className={styles.checkoutFormErrorMessage}>{locale.form.termsError}</div>}*/}
        {/*<CheckoutOptIns/>*/}
        <div className={styles.checkoutFormButtons}>
          <button className={styles.checkoutFormButton}>
            {locale.checkoutPage.buttons.continueToBilling}
            {submission && <ButtonLoader/>}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CheckoutFormContact;
