import drupalApi from '../../services/drupalClientService';
import { SetCartUrl } from './action-creators';

export const CartCheckout = async (cart, customer, user, dispatcher, isApp = false) => {
  let checkoutData = {};
  checkoutData = await drupalApi.cartCheckout(cart, customer, user, isApp);
  if (checkoutData !== false && checkoutData.url) {
    dispatcher(SetCartUrl(checkoutData.url, 'loft'));
    return checkoutData;
  }
};

export const fmkCartCheckout = async (cart, pickup, customer, user, dispatcher) => {
  const checkoutData = await drupalApi.fmkCartCheckout(cart, pickup, customer, user);

  if (checkoutData !== false && checkoutData.url) {
    dispatcher(SetCartUrl(checkoutData.url, 'fmk'));
    // console.log(checkoutData);
    return checkoutData;
  }
};
