import React, { useContext } from 'react';
import getWindow from "../../../libs/getWindow";
import {has, get} from 'lodash';

export const CheckoutContext = React.createContext(1);

export const CheckoutState = () => {
  const [activeStep, setActiveStep] = useContext(CheckoutContext);

  const adjustStep = (step) => {
    setActiveStep(step);
    if (getWindow('document')) {
      const bodyScroll = get(getWindow('document'), 'body', false);
      if (has(bodyScroll, 'scrollTop')) {
        bodyScroll.scrollTop = 0;
      }
      const selector = getWindow('document').querySelector('[class*="useNavigationDrawer-module--drawer--container"]');
      if (has(selector, 'scrollTop')) {
        selector.scrollTop = 0;
      }
    }

  };

  return [activeStep, adjustStep];
};
