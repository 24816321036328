// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
// State
import { useStateValue } from '../../store/state';
import { CheckoutState } from './context/checkout-context';

import { has } from 'lodash';

// Assets
import locale from '../../locale/locale';
import styles from './checkout-form.module.scss';

const CheckoutFormPayment = ({ cartType, customer }) => {
  const [state] = useStateValue();

  // Step tracker
  const [activeStep, adjustStep] = CheckoutState();
  return (
    <div className={styles.checkoutFormPayment}>
      {!has(state.cart[cartType], 'checkoutUrl') ||
        (state.cart[cartType].checkoutUrl === '' && (
          <div className={styles.checkoutFormErrorMessage}>
            {locale.checkoutPage.errors.errorProcessingRequest}
          </div>
        ))}
      {has(state.cart[cartType], 'checkoutUrl') && state.cart[cartType].checkoutUrl !== '' && (
        <iframe id="paymentForm" src={state.cart[cartType].checkoutUrl} title={`Payment Form`} />
      )}
      <div className={styles.checkoutFormButtons}>
        <button
          className={styles.checkoutFormButtonBackToBilling}
          onClick={() => adjustStep(activeStep - 1)}
        >
          {locale.checkoutPage.buttons.backToBilling}
        </button>
      </div>
    </div>
  );
};

CheckoutFormPayment.propTypes = {
  customer: PropTypes.object,
};

export default CheckoutFormPayment;
