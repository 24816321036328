// import { baseApiPath, API_TYPE } from '../libs/api';
import { doRequest } from './doRequest';
import pageAliases from '../data/pageAliases';
import { API_TYPE, getApiPath } from '../libs/api';

export class baseDrupalClientService {
  static instance: baseDrupalClientService;

  constructor() {
    if (baseDrupalClientService.instance) {
      return baseDrupalClientService.instance;
    }

    this.headers = {
      Authorization: 'Basic dGhlcmVmb3JlOmFjY2Vzcw==',
    };

    this.headersPost = {
      ...this.headers,
      'Content-type': 'application/json',
    };

    baseDrupalClientService.instance = this;
  }

  doRequest(method, apiPath, data = null) {
    let url = getApiPath(API_TYPE.content, apiPath);
    // let url = '/apiContent/fmk/' + apiPath;

    return doRequest({
      headers: data === null ? this.headers : this.headersPost,
      method,
      url,
      data,
      auth: {
        username: process.env.BASIC_AUTH_USERNAME,
        password: process.env.BASIC_AUTH_PASSWORD,
      },
    });
  }

  prepareCustomerData(customer, user) {
    return {
      name: `${customer.FirstName} ${customer.LastName}`,
      firstName: customer.FirstName,
      lastName: customer.LastName,
      email: customer.EmailAddress,
      phone: customer.PrimaryPhone,
      address1: customer.PrimaryAddress_Street1,
      address2: customer.PrimaryAddress_Street2,
      city: customer.PrimaryAddress_City,
      province: customer.PrimaryAddress_Province,
      country: customer.PrimaryAddress_Country,
      postal: customer.PrimaryAddress_PostalCode,
      TYRCardNumber: customer.TYRCardNumber,
      CRMCustomerID: customer.CRMCustomerID,
      access_token: user.access_token,
    };
  }

  prepareReturnUrls(type = 'loft', isApp = false) {
    let origin = window.location.origin;
    origin = origin.indexOf('http') >= 0 ? origin : 'http://localhost:8000';
    const prefix = type === 'fmk' ?
      pageAliases.festiveMealKits :
      pageAliases.classes;
    return {
      success: `${origin}${isApp?'/app':''}${prefix}order-complete`,
      failure: `${origin}${isApp?'/app':''}${prefix}order-pending`,
    };
  }

  async cartCheckout(cart, customer, user, isApp = false) {
    const jsonData = {
      customer: this.prepareCustomerData(customer, user),
      returns: this.prepareReturnUrls('loft', isApp),
    };

    jsonData.cart = cart.map((item) => {
      return {
        classScheduleId: item.id,
        guests: item.guests,
        wineParing: item.pairing,
      };
    });

    // console.log('cart', jsonData);
    return this.doRequest('POST', 'longos_checkout/get_checkout_url', jsonData);
  }

  async fmkCartCheckout(cart, pickup, customer, user) {
    const jsonData = {
      customer: this.prepareCustomerData(customer, user),
      returns: this.prepareReturnUrls('fmk'),
      pickUp: pickup,
      cart: cart,
    };

    return this.doRequest('POST', 'longos_checkout/fmk/get_checkout_url', jsonData);
  }

}
