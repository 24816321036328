// Libs
import { useEffect, useState } from 'react';
import { useStateValue } from '../store/state';
// Assets
import pageAliases from '../data/pageAliases';
import navigate from '../libs/navigate';

export default function useCheckoutAccess(cartType) {
  const [state] = useStateValue();
  const cartItems = state.cart[cartType].lineItems;
  const [access, setAccess] = useState(false);

  useEffect(() => {
    // If cart don't have line items redirect to cart page.
    // console.log("Window type:", typeof window);
    if (cartItems.length === 0) {
      setAccess(false);
      navigate(pageAliases.cart);

    } else {
      // console.log('checkout state', state.checkout);

      // Require authentication to access checkout.
      if (!state.user.access_token && !state.checkout.isGuest) {
        setAccess(false);
        navigate(
          `${pageAliases.login}?destination=${encodeURIComponent(
            cartType === 'loft' ? 
              pageAliases.classesCheckout : 
              pageAliases.festiveMealKitsCheckout,
          )}&guest=true`,
        );
      } else {
        setAccess(true);
      }
    }
  }, [state.user.access_token, state.cart[cartType].lineItems]);
  return access;
}
