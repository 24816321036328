import React, { useState, useEffect } from 'react';
import Checkout from './checkout';
import { CheckoutContext } from './context/checkout-context';
import { LoftItemsMapContext, LoftItemsMapInit } from './context/loft-items-map-context';
import { useCartClassSantizer } from '../../libs/class/cart-class-sanitizer';

const CheckoutComponent = ({ cartType }) => {
  // To be used later to display errors
  const { invalidCartItems} = useCartClassSantizer();
  
  const initialLoftItemsMap = LoftItemsMapInit();
  const [activeStep, setActiveStep] = useState(1);
  const [loftItemsMap, setLoftItemsMap] = useState(initialLoftItemsMap);

  // Update local state when cart items changed (removed) so items map context is refreshed
  // across components due is not propagating automatically.
  useEffect(() => {
    setLoftItemsMap(initialLoftItemsMap);
  }, [initialLoftItemsMap.cartItems.length]);

  // console.log("CheckoutComponent");
  return (
    <CheckoutContext.Provider value={[activeStep, setActiveStep]}>
      <LoftItemsMapContext.Provider value={[loftItemsMap, setLoftItemsMap]}>
        <Checkout cartType={cartType} />
      </LoftItemsMapContext.Provider>
    </CheckoutContext.Provider>
  );
};

export default CheckoutComponent;
