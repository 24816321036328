// Libs
import React, {useContext} from 'react';
import * as PropTypes from 'prop-types';
import styles from './checkout-breadcrumb.module.scss';
import icon from '../../images/icons/icon-chevron-grey.svg';
import { CheckoutState } from './context/checkout-context';
import locale from '../../locale/locale';
import {AppContext} from "../layout/AppLayout";

const CheckoutBreadcrumb = () => {
  const isApp = useContext(AppContext);
  const steps = isApp ? [
    locale.checkoutPage.labels.appStepOne,
    locale.checkoutPage.labels.appStepTwo,
    locale.checkoutPage.labels.appStepThree,
  ] : [
    locale.checkoutPage.labels.stepOne,
    locale.checkoutPage.labels.stepTwo,
    locale.checkoutPage.labels.stepThree,
  ];
  const [activeStep] = CheckoutState();
  return (
    <div className={styles.checkoutBreadcrumb}>
      {steps.map((step, i) => {
        let componentClass = `${styles.checkoutBreadcrumbItem}`;
        if (i < activeStep) {
          componentClass = `${styles.checkoutBreadcrumbItem} ${styles.checkoutBreadcrumbItemActive}`;
        }
        return (
          <div key={i} className={componentClass}>
            {step}{' '}
            {i < steps.length - 1 ? (
              <div>
                {' '}
                <img alt="next element" src={icon} />
              </div>
            ) : (
              ''
            )}
          </div>
        );
      })}
    </div>
  );
};

CheckoutBreadcrumb.propTypes = {
  activeState: PropTypes.number,
};

export default CheckoutBreadcrumb;
